import { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import screenshare from "../../assets/sugimoto/screenshare.jpg";
import sleep_wake from "../../assets/sugimoto/sleep_wake.jpg";
import screenshare_2 from "../../assets/sugimoto/screenshare_2.jpg";
import push_to_start from "../../assets/sugimoto/push_to_start.jpg";
import standby from "../../assets/sugimoto/standby.jpg";
import input_1 from "../../assets/sugimoto/input_1.jpg";
import screensaver from "../../assets/sugimoto/screensaver.jpg";
import { useMediaQuery } from "react-responsive";

const ScreensPhotoPage = (props) => {
    useEffect(() => {
        props.setSubheaders([
            { name: "photo:", href: "photo", newTab: false, selected: false },
            { name: "bytes", href: "photo/bytes", newTab: false, selected: false },
            { name: "ai", href: "photo/detection", newTab: false, selected: false },
            { name: "screens", href: "photo/screens", newTab: false, selected: true },
            { name: "hoops", href: "photo/hoops", newTab: false, selected: false },
        ]);
        document.title = "nathan baker // screens";
    }, []);

    const isTouchscreen = !useMediaQuery({
        query: "(hover: hover) and (pointer: fine)",
    });

    return (
        <div>
            <div className="carousel-container">
                <div className="photo-set-container">
                    <Slider
                        {...{
                            speed: isTouchscreen ? 750 : 1500,
                            centerMode: false,
                            slidesToShow: 1,
                            dots: true,
                            variableWidth: false,
                            adaptiveHeight: true,
                            infinite: true,
                            autoplay: true,
                            autoplaySpeed: 3000,
                            pauseOnFocus: false,
                            pauseOnHover: false,
                            focusOnSelect: false,
                            arrows: false,
                            fade: isTouchscreen ? false : true,
                            onInit: async () => {
                                await new Promise((r) => setTimeout(r, 100));
                                const images = document.getElementsByClassName("photo");
                                for (let image of images) {
                                    image.style.opacity = 1;
                                }
                            },
                        }}
                    >
                        <div>
                            <img className="photo" src={screenshare} alt="" />
                            <div className="settings-table settings-table-titled">
                                <p>
                                    <em>screen-share</em>
                                </p>
                                <p>ISO 100</p>
                                <p>16 mm</p>
                                <p>f/4.0</p>
                                <p>1/4 sec</p>
                            </div>
                        </div>
                        <div>
                            <img className="photo" src={sleep_wake} alt="" />
                            <div className="settings-table settings-table-titled">
                                <p>
                                    <em>sleep/wake button</em>
                                </p>
                                <p>ISO 100</p>
                                <p>16 mm</p>
                                <p>f/4.0</p>
                                <p>20 sec</p>
                            </div>
                        </div>
                        <div>
                            <img className="photo" src={screenshare_2} alt="" />
                            <div className="settings-table settings-table-titled">
                                <p>
                                    <em>screen-share 2</em>
                                </p>
                                <p>ISO 100</p>
                                <p>16 mm</p>
                                <p>f/4.0</p>
                                <p>1.0 sec</p>
                            </div>
                        </div>
                        <div>
                            <img className="photo" src={push_to_start} alt="" />
                            <div className="settings-table settings-table-titled">
                                <p>
                                    <em>push to start</em>
                                </p>
                                <p>ISO 100</p>
                                <p>50 mm</p>
                                <p>f/5.6</p>
                                <p>20 sec</p>
                            </div>
                        </div>
                        <div>
                            <img className="photo" src={standby} alt="" />
                            <div className="settings-table settings-table-titled">
                                <p>
                                    <em>standby</em>
                                </p>
                                <p>ISO 100</p>
                                <p>16 mm</p>
                                <p>f/4.0</p>
                                <p>30 sec</p>
                            </div>
                        </div>
                        <div>
                            <img className="photo" src={input_1} alt="" />
                            <div className="settings-table settings-table-titled">
                                <p>
                                    <em>input 1</em>
                                </p>
                                <p>ISO 100</p>
                                <p>50 mm</p>
                                <p>f/5.6</p>
                                <p>30 sec</p>
                            </div>
                        </div>
                        <div>
                            <img className="photo" src={screensaver} alt="" />
                            <div className="settings-table settings-table-titled">
                                <p>
                                    <em>screensaver</em>
                                </p>
                                <p>ISO 100</p>
                                <p>50 mm</p>
                                <p>f/5.6</p>
                                <p>4.0 sec</p>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
            <br />
            <p className="description">
                A direct extension of Hiroshi Sugimoto's well-known&nbsp;
                <a
                    className="gray-hover"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.sugimotohiroshi.com/new-page-7"
                >
                    <u>Theatres</u>
                </a>
                , this project employs prolonged exposures to obscure the contents of dynamic displays and explore the
                pervasive presence of technology in an indivdiual's personal habitat. By isolating various "smart"
                devices as the sole sources of light, these images emphasize the extent to which modern beings depend on
                such devices and the software inside them. <br />
                <br />
                The initial trajectory of this project closely mirrored that of Sugimoto. I started by photographing
                vast, empty lecture halls on the Oklahoma State University campus, painstakingly compositing nearly a
                hundred photos of my laptop at each desk to resurrect the atmosphere of a computer science lecture.
                However, after capturing just two of these spaces, the scope of this endeavor was severely confined by
                an emerging global pandemic. The constraints of quarantine forced me to consider more intimate places in
                which screens lurk, rather than public educational ones &mdash; a shift which resulted in a blend of
                familiarity and eeriness that I did not foresee.
            </p>
            <br />
            <p className="gray center">
                'Reference' project for Photography II
                <br />
                07 April 2020
            </p>
        </div>
    );
};

export default ScreensPhotoPage;
