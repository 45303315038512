import { useEffect } from "react";
import { Link } from "react-router-dom";
import trifold from "../../assets/half-flip/trifold-spring-1280.mp4";
import { useMediaQuery } from "react-responsive";

const HalfFlipPage = (props) => {
    useEffect(() => {
        props.setSubheaders([
            { name: "code:", href: "code", newTab: false, selected: false },
            { name: "half-flip", href: "code/half-flip", newTab: false, selected: true },
            { name: "seascape", href: "code/seascape", newTab: false, selected: false },
            { name: "rl-ai", href: "code/rl-ai", newTab: false, selected: false },
        ]);
        document.title = "nathan baker // half-flip";
    }, []);

    const sm = useMediaQuery({
        query: "(min-width: 600px)",
    });

    return (
        <>
            <video
                playsInline
                autoPlay
                muted
                loop
                style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    objectPosition: "center",
                    // border: "max(0.5vw, 3px) solid black",
                    boxSizing: "border-box",
                    borderRadius: "8px",
                    marginBottom: sm ? "8px" : "",
                    opacity: 0,
                    transition: "opacity 1s",
                }}
                onLoadedData={(e) => (e.target.style.opacity = 1)}
            >
                <source src={trifold} type="video/mp4" />
            </video>
            <p>
                As Rocket League fans, my brother and I often struggle to keep up with the evolving esports scene, so we
                were looking for a tool that would help us stay up to date while providing some fun competition. After
                getting my first few months of web development experience, I decided to take a stab at creating our own
                pick'em website for RL.
                <br />
                <br />
                The site includes bracket competitions as well as stat-based questions that update in realtime alongside
                major events in the Rocket League Championship Series. Results are automatically processed based on the
                data from the replay files uploaded after each game.
                <br />
                <br />
                <div style={{ textAlign: sm ? "" : "center" }}>
                    <span style={{ textDecoration: sm ? "" : "underline" }}>
                        <b>Development Stack:</b>
                    </span>
                    &ensp;{!sm ? <br /> : ""}
                    <span className="blue">React.js + Material-UI,</span>
                    &ensp;{!sm ? <br /> : ""}
                    <span className="yellow">Firebase Realtime Database,</span>&ensp;{!sm ? <br /> : ""}
                    <span className="red">Node.js</span>
                    {!sm ? (
                        <>
                            <br />
                            <br />
                        </>
                    ) : (
                        ""
                    )}
                </div>
                <span className="gray">
                    This project is not open source, but still reach out if you have ideas or want to collaborate!
                </span>
                <br />
                <br />
                <div style={{ textAlign: sm ? "" : "center" }}>
                    <Link to="https://half-flip.site" target="_blank" className="gray-hover">
                        <u>https://half-flip.site</u>
                    </Link>
                </div>
            </p>
        </>
    );
};

export default HalfFlipPage;
