import { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bench from "../../assets/ai/bench.jpg";
import dining_table from "../../assets/ai/dining_table.jpg";
import mailbox from "../../assets/ai/mailbox.jpg";
import umbrella from "../../assets/ai/umbrella.jpg";
import person from "../../assets/ai/person.jpg";
import frisbee from "../../assets/ai/frisbee.jpg";
import suitcase from "../../assets/ai/suitcase.jpg";
import { useMediaQuery } from "react-responsive";

const DetectionPhotoPage = (props) => {
    useEffect(() => {
        props.setSubheaders([
            { name: "photo:", href: "photo", newTab: false, selected: false },
            { name: "bytes", href: "photo/bytes", newTab: false, selected: false },
            { name: "ai", href: "photo/detection", newTab: false, selected: true },
            { name: "screens", href: "photo/screens", newTab: false, selected: false },
            { name: "hoops", href: "photo/hoops", newTab: false, selected: false },
        ]);
        document.title = "nathan baker // object recognition photography";
    }, []);

    const isTouchscreen = !useMediaQuery({
        query: "(hover: hover) and (pointer: fine)",
    });

    return (
        <div>
            <div className="carousel-container">
                <div className="photo-set-container">
                    <Slider
                        {...{
                            speed: isTouchscreen ? 750 : 1500,
                            centerMode: false,
                            dots: true,
                            variableWidth: true,
                            adaptiveHeight: true,
                            infinite: true,
                            autoplay: true,
                            autoplaySpeed: 3000,
                            pauseOnFocus: false,
                            pauseOnHover: false,
                            focusOnSelect: false,
                            arrows: false,
                            fade: isTouchscreen ? false : true,
                            onInit: async () => {
                                await new Promise((r) => setTimeout(r, 100));
                                const images = document.getElementsByClassName("photo");
                                for (let image of images) {
                                    image.style.opacity = 1;
                                }
                            },
                        }}
                    >
                        <div>
                            <img className="photo" src={bench} alt="" />
                            <div className="settings-table settings-table-titled">
                                <p>bench: &nbsp;47%</p>
                                <p>ISO 1250</p>
                                <p>50 mm</p>
                                <p>f/2.8</p>
                                <p>1/125 sec</p>
                            </div>
                        </div>
                        <div>
                            <img className="photo" src={dining_table} alt="" />
                            <div className="settings-table settings-table-titled">
                                <p>dining table: &nbsp;18%</p>
                                <p>ISO 500</p>
                                <p>50 mm</p>
                                <p>f/4.0</p>
                                <p>1/125 sec</p>
                            </div>
                        </div>
                        <div>
                            <img className="photo" src={mailbox} alt="" />
                            <div className="settings-table settings-table-titled">
                                <p>mailbox: &nbsp;54%</p>
                                <p>ISO 125</p>
                                <p>50 mm</p>
                                <p>f/5.6</p>
                                <p>1/125 sec</p>
                            </div>
                        </div>
                        <div>
                            <img className="photo" src={umbrella} alt="" />
                            <div className="settings-table settings-table-titled">
                                <p>umbrella: &nbsp;4%</p>
                                <p>ISO 500</p>
                                <p>50 mm</p>
                                <p>f/4.0</p>
                                <p>1/125 sec</p>
                            </div>
                        </div>
                        <div>
                            <img className="photo" src={person} alt="" />
                            <div className="settings-table settings-table-titled">
                                <p>person: &nbsp;58%</p>
                                <p>ISO 400</p>
                                <p>50 mm</p>
                                <p>f/4.0</p>
                                <p>1/90 sec</p>
                            </div>
                        </div>
                        <div>
                            <img className="photo" src={frisbee} alt="" />
                            <div className="settings-table settings-table-titled">
                                <p>frisbee: &nbsp;28%</p>
                                <p>ISO 200</p>
                                <p>50 mm</p>
                                <p>f/11</p>
                                <p>6 sec</p>
                            </div>
                        </div>
                        <div>
                            <img className="photo" src={suitcase} alt="" />
                            <div className="settings-table settings-table-titled">
                                <p>suitcase: &nbsp;3%</p>
                                <p>ISO 500</p>
                                <p>50 mm</p>
                                <p>f/4.0</p>
                                <p>1/125 sec</p>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
            <br />
            <p className="description">
                As Trevor Paglen mindfully points out in his essay&nbsp;
                <a
                    className="gray-hover"
                    target="_blank"
                    rel="noreferrer"
                    href="https://thenewinquiry.com/invisible-images-your-pictures-are-looking-at-you/"
                >
                    <em>
                        <u>Invisible Images (Your Pictures are Looking at You)</u>
                    </em>
                </a>
                , the invention of digital photography has spawned a sea of machine-readable pictures that we will never
                see. Today, digital cameras are being used to serve a myriad of purposes beyond artistic expression or
                personal documentation &mdash; we not only have cameras in our phones, but also outside our homes and on
                our cars. Millions of images are generated and parsed by machines on a daily basis, without any need for
                human participation.
                <br />
                <br />
                Part satire and part study, this work aims to uncover how modern artificial intelligence perceives human
                civilization by forcing a system to rationalize bizarre or unfamiliar scenes. Photographs are fed as
                inputs to&nbsp;
                <a
                    className="gray-hover"
                    target="_blank"
                    rel="noreferrer"
                    href="https://github.com/google/automl/tree/master/efficientdet"
                >
                    <u>EfficientDet D7</u>
                </a>
                , an artificial neural network specially designed by&nbsp;
                <a className="gray-hover" target="_blank" rel="noreferrer" href="https://research.google/teams/brain/">
                    <u>Google Brain</u>
                </a>
                &nbsp;to detect objects in pictures. As a result, each individual frame bears an overlay depicting the
                model's inferences about the underlying image, with presumed items denoted by bounding boxes and
                confidence ratings.
            </p>
            <br />
            <p className="gray center">
                Completed as part of Photography Studio
                <br />
                23 September 2021
            </p>
        </div>
    );
};

export default DetectionPhotoPage;
