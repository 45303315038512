import { useEffect, useRef } from "react";

const FadeInImage = (props) => {
    const image = useRef();

    const isCached = (src) => {
        var image = new Image();
        image.src = src;
        return image.complete;
    };

    useEffect(() => {
        if (isCached(props.src)) {
            image.current.style.opacity = 1;
            image.current.style.animation = "none";
            if (props.callback instanceof Function) props.callback("cached");
        } else {
            image.current.style.opacity = 0;
            if (props.callback instanceof Function) props.callback(false);
        }
    }, []);

    return (
        <img
            ref={image}
            className={props.className}
            loading="lazy"
            onLoad={() => {
                if (image.current.style.opacity === "0" || image.current.style.opacity === 0) {
                    image.current.style.animation = "fadeIn 0.4s ease-out";
                    if (props.callback instanceof Function) props.callback("loaded");
                }
                image.current.style.opacity = 1;
            }}
            src={props.src}
            style={props.style}
            alt=""
        />
    );
};

export default FadeInImage;
