import { Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import FadeInImage from "./FadeInImage";

const LinkCard = (props) => {
    const [hovering, setHovering] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(null);

    const isTouchscreen = !useMediaQuery({
        query: "(hover: hover) and (pointer: fine)",
    });

    let leftSymbol;
    let rightSymbol;
    if (props.symbol === "angled") {
        leftSymbol = "<";
        rightSymbol = ">";
    } else if (props.symbol === "bracket") {
        leftSymbol = "[";
        rightSymbol = "]";
    }

    useEffect(() => {
        if (imageLoaded && imageLoaded !== true) {
            setImageLoaded(true);
        }
    }, [imageLoaded]);

    const darken = hovering || (isTouchscreen && props.defaultHover);

    return (
        <div
            className={props.className}
            id={props.id}
            style={{ position: "relative", maxHeight: "100%", overflow: "hidden", ...props.style }}
            onTouchStart={() => setHovering(true)}
            onTouchEnd={() => setHovering(false)}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setTimeout(() => setHovering(false), 40)}
            onBlur={() => setHovering(false)}
        >
            <Skeleton sx={{ paddingTop: "100%", transform: "none", height: "100%" }} width="100%" />
            <div style={{ width: "100%", height: "100%", overflow: "hidden", position: "absolute", inset: 0 }}>
                <FadeInImage
                    callback={setImageLoaded}
                    src={props.src}
                    style={{
                        transition: "transform 0.8s ease",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        transform: hovering ? "scale(1.02)" : "",
                    }}
                />
            </div>
            <div
                className="card-title"
                style={{
                    display: "flex",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    zIndex: "2",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    background: hovering || darken ? "rgba(0,0,0,0.6)" : "rgba(0,0,0,0.1)",
                    transition: imageLoaded === "cached" ? "" : "all 0.6s ease",
                    opacity: !imageLoaded ? 0 : hovering ? 1 : 0.7,
                    boxShadow: "inset 0px 0px 60px rgba(0,0,0,0.6)",
                    ...props.style?.[".card-title"],
                }}
            >
                <h1
                    style={{ transition: "color 0.4s ease", ...props.style?.[".card-title"]?.["h1"] }}
                    className={"mono " + (hovering || darken ? props.color : "white")}
                >
                    {leftSymbol}
                </h1>
                <h1
                    style={{ transition: "color 0.4s ease", ...props.style?.[".card-title"]?.["h1"] }}
                    className="mono white"
                >
                    {props.display}
                </h1>
                <h1
                    style={{ transition: "color 0.4s ease", ...props.style?.[".card-title"]?.["h1"] }}
                    className={"mono " + (hovering || darken ? props.color : "white")}
                >
                    {rightSymbol}
                </h1>
            </div>
        </div>
    );
};

export default LinkCard;
