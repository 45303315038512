import { useEffect } from "react";
import { Skeleton } from "@mui/material";

const IrishVideoPage = (props) => {
    useEffect(() => {
        props.setSubheaders([
            { name: "video:", href: "video", newTab: false, selected: false },
            { name: "irish_basketball", href: "video/irish", newTab: false, selected: true },
            { name: "vlog", href: "video/vlog", newTab: false, selected: false },
        ]);
        document.title = "nathan baker // irish basketball videos";
    }, []);

    return (
        <div>
            <p>
                I am very grateful that I was asked to create videos for Bishop McGuinness's varsity basketball team
                based on my previous work as a student there. It was awesome to see some familiar faces and get to give
                back to this community for two consecutive years. Directing this type of video was a new experience for
                me, and I'm happy with how they turned out!
            </p>
            <br />
            <div style={{ position: "relative", flexBasis: "100%", marginBottom: "8px" }}>
                <Skeleton sx={{ paddingTop: "50%", transform: "none" }} width="100%" />
                <iframe
                    src="https://www.youtube.com/embed/k_sO9u1_Xyc?rel=0&modestbranding=1"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Promotional video for 2020-2021 season"
                    style={{ position: "absolute", inset: 0, height: "100%", marginTop: 0 }}
                />
            </div>
            <p className="label">
                Promotional video for 2020-2021 season.
                <br />
            </p>
            <div style={{ position: "relative", flexBasis: "100%", marginBottom: "8px" }}>
                <Skeleton sx={{ paddingTop: "50%", transform: "none" }} width="100%" />
                <iframe
                    src="https://www.youtube.com/embed/CtOfy6OzbKM?rel=0&modestbranding=1"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Promotional video for 2019-2020 season"
                    style={{ position: "absolute", inset: 0, height: "100%", marginTop: 0 }}
                />
            </div>
            <p className="label">Promotional video for 2019-2020 season.</p>
        </div>
    );
};

export default IrishVideoPage;
