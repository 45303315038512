import { useEffect } from "react";
import { Skeleton } from "@mui/material";

const VlogPage = (props) => {
    useEffect(() => {
        props.setSubheaders([
            { name: "video:", href: "video", newTab: false, selected: false },
            { name: "irish_basketball", href: "video/irish", newTab: false, selected: false },
            { name: "vlog", href: "video/vlog", newTab: false, selected: true },
        ]);
        document.title = "nathan baker // vlog";
    }, []);

    return (
        <div>
            <p>
                Spurred by the stark realization that I had only one semester of college remaining, and inspired by
                creators&nbsp;
                <a
                    className="gray-hover"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.youtube.com/channel/UCHnwxX3L36-hXOrck-2hH0g"
                >
                    <u>Noah Buhl</u>
                </a>
                ,
                <a
                    className="gray-hover"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.youtube.com/user/arossmorales"
                >
                    {" "}
                    <u>Ross Morales</u>
                </a>
                , and&nbsp;
                <a
                    className="gray-hover"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.youtube.com/channel/UC3DkFux8Iv-aYnTRWzwaiBA"
                >
                    <u>Peter McKinnon</u>
                </a>
                , this has been my attempt to archive a stage of my life that I don't want to forget. Assembling these
                monthly(ish) videos has been a rewarding exercise of creativity and risk, and I wish to continue the
                series if time and motivation permit.
            </p>
            <br />
            <div style={{ position: "relative", flexBasis: "100%", marginBottom: "8px" }}>
                <Skeleton sx={{ paddingTop: "50%", transform: "none" }} width="100%" />
                <iframe
                    src="https://www.youtube.com/embed/p25hwWhtoXw?rel=0&modestbranding=1"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Promotional video for 2020-2021 season"
                    style={{ position: "absolute", inset: 0, height: "100%", marginTop: 0 }}
                />
            </div>
            <p className="label">
                30 September 2021
                <br />
            </p>
            <div style={{ position: "relative", flexBasis: "100%", marginBottom: "8px" }}>
                <Skeleton sx={{ paddingTop: "50%", transform: "none" }} width="100%" />
                <iframe
                    src="https://www.youtube.com/embed/jcru0YvqapM?rel=0&modestbranding=1"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Promotional video for 2020-2021 season"
                    style={{ position: "absolute", inset: 0, height: "100%", marginTop: 0 }}
                />
            </div>
            <p className="label">
                12 September 2021
                <br />
            </p>
            <div style={{ position: "relative", flexBasis: "100%", marginBottom: "8px" }}>
                <Skeleton sx={{ paddingTop: "50%", transform: "none" }} width="100%" />
                <iframe
                    src="https://www.youtube.com/embed/cS-ZnP_Ir7A?rel=0&modestbranding=1"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Promotional video for 2019-2020 season"
                    style={{ position: "absolute", inset: 0, height: "100%", marginTop: 0 }}
                />
            </div>
            <p className="label">16 August 2021</p>
        </div>
    );
};

export default VlogPage;
