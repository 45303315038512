import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import "./styles.css";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import PortfolioPage from "./pages/PortfolioPage";
import Background from "./components/Background";
import Header from "./components/Header";
import Footer from "./components/Footer";
import PhotoPage from "./pages/PhotoPage";
import CodePage from "./pages/CodePage";
import VideoPage from "./pages/VideoPage";
import RLAIPage from "./pages/code/RLAIPage";
import IrishVideoPage from "./pages/video/IrishVideoPage";
import VlogPage from "./pages/video/VlogPage";
import BytesPhotoPage from "./pages/photo/BytesPhotoPage";
import DetectionPhotoPage from "./pages/photo/DetectionPhotoPage";
import ScreensPhotoPage from "./pages/photo/ScreensPhotoPage";
import HoopsPhotoPage from "./pages/photo/HoopsPhotoPage";
import SeascapePage from "./pages/code/SeascapePage";
import HalfFlipPage from "./pages/code/HalfFlipPage";

const App = () => {
    const colors = [" orange", " blue", " red", " green", " yellow", " purple", " pink"];
    const primaryColor = colors[Math.floor(Math.random() * colors.length)];
    const index = colors.indexOf(primaryColor);
    if (index !== -1) colors.splice(index, 1);
    const secondaryColor = colors[Math.floor(Math.random() * colors.length)];

    const [subheaders, setSubheaders] = useState([]);

    return (
        <>
            <Background />
            <div
                style={{
                    backgroundColor: "rgba(255,255,255,0.5)",
                    boxShadow: "0 0 100px rgba(255,255,255,0.5)",
                }}
            >
                <Header primaryColor={primaryColor} secondaryColor={secondaryColor} subheaders={subheaders} />
                <Routes>
                    <Route
                        exact
                        path="/"
                        element={
                            <div className="page-container">
                                <HomePage setSubheaders={setSubheaders} />
                                <Footer primaryColor={primaryColor} secondaryColor={secondaryColor} />
                            </div>
                        }
                    />
                    <Route
                        exact
                        path="/react"
                        element={
                            <div className="page-container">
                                <HomePage setSubheaders={setSubheaders} />
                                <Footer primaryColor={primaryColor} secondaryColor={secondaryColor} />
                            </div>
                        }
                    />
                    <Route
                        exact
                        path="/about"
                        element={
                            <div className="page-container">
                                <AboutPage setSubheaders={setSubheaders} />
                                <Footer primaryColor={primaryColor} secondaryColor={secondaryColor} />
                            </div>
                        }
                    />
                    <Route
                        exact
                        path="/portfolio"
                        element={
                            <div className="page-container" id="portfolio-page">
                                <PortfolioPage setSubheaders={setSubheaders} />
                                <Footer primaryColor={primaryColor} secondaryColor={secondaryColor} />
                            </div>
                        }
                    />
                    <Route
                        exact
                        path="/code"
                        element={
                            <div className="page-container">
                                <CodePage setSubheaders={setSubheaders} />
                                <Footer primaryColor={primaryColor} secondaryColor={secondaryColor} />
                            </div>
                        }
                    />
                    <Route
                        exact
                        path="/photo"
                        element={
                            <div className="page-container">
                                <PhotoPage setSubheaders={setSubheaders} />
                                <Footer primaryColor={primaryColor} secondaryColor={secondaryColor} />
                            </div>
                        }
                    />
                    <Route
                        exact
                        path="/video"
                        element={
                            <div className="page-container">
                                <VideoPage setSubheaders={setSubheaders} />
                                <Footer primaryColor={primaryColor} secondaryColor={secondaryColor} />
                            </div>
                        }
                    />
                    <Route
                        exact
                        path="/code/rl-ai"
                        element={
                            <div className="page-container">
                                <RLAIPage setSubheaders={setSubheaders} />
                                <Footer primaryColor={primaryColor} secondaryColor={secondaryColor} />
                            </div>
                        }
                    />
                    <Route
                        exact
                        path="/video/irish"
                        element={
                            <div className="page-container">
                                <IrishVideoPage setSubheaders={setSubheaders} />
                                <Footer primaryColor={primaryColor} secondaryColor={secondaryColor} />
                            </div>
                        }
                    />
                    <Route
                        exact
                        path="/video/vlog"
                        element={
                            <div className="page-container">
                                <VlogPage setSubheaders={setSubheaders} />
                                <Footer primaryColor={primaryColor} secondaryColor={secondaryColor} />
                            </div>
                        }
                    />
                    <Route
                        exact
                        path="/photo/bytes"
                        element={
                            <div className="page-container">
                                <BytesPhotoPage setSubheaders={setSubheaders} />
                                <Footer primaryColor={primaryColor} secondaryColor={secondaryColor} />
                            </div>
                        }
                    />
                    <Route
                        exact
                        path="/photo/detection"
                        element={
                            <div className="page-container">
                                <DetectionPhotoPage setSubheaders={setSubheaders} />
                                <Footer primaryColor={primaryColor} secondaryColor={secondaryColor} />
                            </div>
                        }
                    />
                    <Route
                        exact
                        path="/photo/screens"
                        element={
                            <div className="page-container">
                                <ScreensPhotoPage setSubheaders={setSubheaders} />
                                <Footer primaryColor={primaryColor} secondaryColor={secondaryColor} />
                            </div>
                        }
                    />
                    <Route
                        exact
                        path="/photo/hoops"
                        element={
                            <div className="page-container" id="multi-photo-page">
                                <HoopsPhotoPage setSubheaders={setSubheaders} />
                                <Footer primaryColor={primaryColor} secondaryColor={secondaryColor} />
                            </div>
                        }
                    />
                    <Route
                        exact
                        path="/code/half-flip"
                        element={
                            <div className="page-container">
                                <HalfFlipPage setSubheaders={setSubheaders} />
                                <Footer primaryColor={primaryColor} secondaryColor={secondaryColor} />
                            </div>
                        }
                    />
                    <Route
                        exact
                        path="/code/seascape"
                        element={
                            <div className="page-container">
                                <SeascapePage setSubheaders={setSubheaders} />
                                <Footer primaryColor={primaryColor} secondaryColor={secondaryColor} />
                            </div>
                        }
                    />
                </Routes>
            </div>
        </>
    );
};

export default App;
