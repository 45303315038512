import { useEffect } from "react";
import { Link } from "react-router-dom";
import "../portfolio-styles.css";
import code from "../assets/code2-min.jpg";
import chrain3 from "../assets/chrain3-min.jpg";
import jungle20 from "../assets/jungle20-min.jpg";
import LinkCard from "../components/LinkCard";

const PortfolioPage = (props) => {
    useEffect(() => {
        props.setSubheaders([
            { name: "home", href: "/", newTab: false, selected: false },
            { name: "about", href: "about", newTab: false, selected: false },
            { name: "resume", href: `${process.env.PUBLIC_URL}/files/resume.pdf`, newTab: true, selected: false },
            { name: "portfolio", href: "portfolio", newTab: false, selected: true },
        ]);
        document.title = "nathan baker // portfolio";
    }, []);

    return (
        <div className="portfolio-container">
            <Link to="/code">
                <LinkCard
                    defaultHover
                    src={code}
                    className="portfolio-card"
                    id="code-card"
                    color="red"
                    symbol="angled"
                    display="code"
                />
            </Link>
            <Link to="/photo">
                <LinkCard
                    defaultHover
                    src={chrain3}
                    className="portfolio-card"
                    id="photo-card"
                    color="orange"
                    symbol="angled"
                    display="photo"
                />
            </Link>
            <Link to="/video">
                <LinkCard
                    defaultHover
                    src={jungle20}
                    className="portfolio-card"
                    id="video-card"
                    color="green"
                    symbol="angled"
                    display="video"
                />
            </Link>
        </div>
    );
};

export default PortfolioPage;
