import { useEffect } from "react";
import { Link } from "react-router-dom";
import me from "../assets/me.jpg";
import me_vertical from "../assets/me_vertical.jpg";
import FadeInImage from "../components/FadeInImage";
import { useMediaQuery } from "react-responsive";

const AboutPage = (props) => {
    useEffect(() => {
        props.setSubheaders([
            { name: "home", href: "/", newTab: false, selected: false },
            { name: "about", href: "about", newTab: false, selected: true },
            { name: "resume", href: `${process.env.PUBLIC_URL}/files/resume.pdf`, newTab: true, selected: false },
            { name: "portfolio", href: "portfolio", newTab: false, selected: false },
        ]);
        document.title = "nathan baker // about";
    }, []);

    const sm = useMediaQuery({
        query: "(min-width: 600px)",
    });

    const ageDifMs = Date.now() - new Date("05/02/2000");
    const age = Math.abs(new Date(ageDifMs).getUTCFullYear() - 1970);

    return (
        <>
            <FadeInImage src={sm ? me : me_vertical} />
            <p>
                I am {age} years old, and I graduated in December 2021 with a bachelor's degree in computer science and
                minor in mathematics.
                <br />
                <br />
                As a motivated software developer, I like to make things. I am especially interested in web development,
                photography, and machine learning. My recent programming projects include building an&nbsp;
                <Link to="/code/half-flip" className="gray-hover">
                    <u>esports prediction website</u>
                </Link>
                &nbsp;as well as&nbsp;
                <Link to="/code/rl-ai" className="gray-hover">
                    <u>training a bot</u>
                </Link>
                &nbsp;for my favorite game, Rocket League.
                <br />
                <br />
                I'm also obsessed with keyboards, oatmeal cookies, and the parallelograms that window light makes on
                walls.
                <br />
                <br />
            </p>
        </>
    );
};

export default AboutPage;
