// import dots from "../assets/dots.mp4";
import lines from "../assets/lines.mp4";
import linesFrame from "../assets/lines-frame.png";

const Background = (props) => {
    return (
        <div style={{ position: "fixed", top: "0", left: "0", bottom: "0", right: "0", zIndex: "-1" }}>
            {/* <video
                playsInline
                autoPlay
                muted
                loop
                style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    filter: "contrast(.75) saturate(0) brightness(1.5)",
                    animation: "subtleBreathing 10s ease-in-out infinite",
                }}
            >
                <source src={lines} type="video/mp4" />
            </video> */}
            <img
                src={linesFrame}
                alt=""
                style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    filter: "contrast(.5) saturate(0) brightness(1.5)",
                    opacity: 0.175,
                }}
            />
        </div>
    );
};

export default Background;
