import keyboardImage from "../assets/keyboard.jpg";
import switches from "../assets/switches.jpg";
import { useEffect } from "react";
import FadeInImage from "../components/FadeInImage";
import LinkCard from "../components/LinkCard";

const HomePage = (props) => {
    useEffect(() => {
        props.setSubheaders([
            { name: "home", href: "/", newTab: false, selected: true },
            { name: "about", href: "about", newTab: false, selected: false },
            { name: "resume", href: `${process.env.PUBLIC_URL}/files/resume.pdf`, newTab: true, selected: false },
            { name: "portfolio", href: "portfolio", newTab: false, selected: false },
        ]);
        document.title = "nathan baker";
    }, []);

    return (
        <>
            <FadeInImage src={keyboardImage} />
            <p>
                Hi there, I'm Nathan! I'm a computer science graduate from <em>Oklahoma State University</em>, currently
                working as a software developer at <em>Paycom</em>. <br />
                <br />
                Feel free to connect with me at any of the links below!
            </p>
            <img className="hidden" src="/assets/switches.jpg" alt="" />
            <div className="link-container">
                <br />
                <a target="_blank" rel="noreferrer" href="mailto:nathan.t.baker@okstate.edu">
                    <LinkCard
                        defaultHover
                        className="home-link-card"
                        display="email"
                        symbol="angled"
                        color="orange"
                        src={switches}
                    />
                </a>
                <br />
                <a target="_blank" rel="noreferrer" href="https://linkedin.com/in/nathan-baker-16173b1b8/">
                    <LinkCard
                        defaultHover
                        className="home-link-card"
                        display="linkedin"
                        symbol="angled"
                        color="blue"
                        src={switches}
                    />
                </a>
                <br />
                <a target="_blank" rel="noreferrer" href="https://instagram.com/nate_bake_/">
                    <LinkCard
                        defaultHover
                        className="home-link-card"
                        display="instagram"
                        symbol="angled"
                        color="pink"
                        src={switches}
                    />
                </a>
                <br />
                <a target="_blank" rel="noreferrer" href="https://youtube.com/channel/UCDMhU58fPfb7kqvJJNVgU5w">
                    <LinkCard
                        defaultHover
                        className="home-link-card"
                        display="youtube"
                        symbol="angled"
                        color="red"
                        src={switches}
                    />
                </a>
                <br />
                <a target="_blank" rel="noreferrer" href="https://open.spotify.com/user/naynaybakebake">
                    <LinkCard
                        defaultHover
                        className="home-link-card"
                        display="spotify"
                        symbol="angled"
                        color="green"
                        src={switches}
                    />
                </a>
                <br />
                <a target="_blank" rel="noreferrer" href="https://github.com/nate-bake">
                    <LinkCard
                        defaultHover
                        className="home-link-card"
                        display="github"
                        symbol="angled"
                        color="purple"
                        src={switches}
                    />
                </a>
            </div>
        </>
    );
};

export default HomePage;
