import { useEffect } from "react";
import { Link } from "react-router-dom";
import irish2 from "../assets/tiles/irish2.jpg";
import input1 from "../assets/tiles/input_1.jpg";
import ai2 from "../assets/tiles/ai2.jpg";
import purple2 from "../assets/tiles/purple2.jpg";
import LinkCard from "../components/LinkCard";
import { useMediaQuery } from "react-responsive";
import { Skeleton } from "@mui/material";

const PhotoPage = (props) => {
    useEffect(() => {
        props.setSubheaders([
            { name: "portfolio:", href: "portfolio", newTab: false, selected: false },
            { name: "code", href: "code", newTab: false, selected: false },
            { name: "photo", href: "photo", newTab: false, selected: true },
            { name: "video", href: "video", newTab: false, selected: false },
        ]);
        document.title = "nathan baker // photo";
    }, []);

    const md = useMediaQuery({
        query: "(min-width: 900px)",
    });

    return (
        <div className="menu-container">
            <Link to="/photo/bytes" style={{ flexBasis: md ? "20%" : "40%" }}>
                <LinkCard
                    src={purple2}
                    className="photo-card"
                    id="bytes-card"
                    color="purple"
                    symbol="bracket"
                    display="bytes"
                    style={{
                        ".card-title": {
                            h1: {
                                fontSize: md ? "max(min(2.5vw, 2.25rem), 1.5rem)" : "max(min(5vw, 2.5rem), 1.25rem)",
                            },
                        },
                    }}
                />
            </Link>
            <Link to="/photo/detection" style={{ flexBasis: md ? "20%" : "40%" }}>
                <LinkCard
                    src={ai2}
                    className="photo-card"
                    id="detection-card"
                    color="red"
                    symbol="bracket"
                    display="detection"
                    style={{
                        ".card-title": {
                            h1: {
                                fontSize: md ? "max(min(2.5vw, 2.25rem), 1.5rem)" : "max(min(5vw, 2.5rem), 1.25rem)",
                            },
                        },
                    }}
                />
            </Link>
            <Link to="/photo/screens" style={{ flexBasis: md ? "20%" : "40%" }}>
                <LinkCard
                    src={input1}
                    className="photo-card"
                    id="screens-card"
                    color="yellow"
                    symbol="bracket"
                    display="screens"
                    style={{
                        ".card-title": {
                            h1: {
                                fontSize: md ? "max(min(2.5vw, 2.25rem), 1.5rem)" : "max(min(5vw, 2.5rem), 1.25rem)",
                            },
                        },
                    }}
                />
            </Link>
            <Link to="/photo/hoops" style={{ flexBasis: md ? "20%" : "40%" }}>
                <LinkCard
                    src={irish2}
                    className="photo-card"
                    id="hoops-card"
                    color="green"
                    symbol="bracket"
                    display="hoops"
                    style={{
                        ".card-title": {
                            h1: {
                                fontSize: md ? "max(min(2.5vw, 2.25rem), 1.5rem)" : "max(min(5vw, 2.5rem), 1.25rem)",
                            },
                        },
                    }}
                />
            </Link>
            <div style={{ position: "relative", flexBasis: "100%" }}>
                <Skeleton sx={{ paddingTop: md ? "50%" : "100%", transform: "none" }} width="100%" />
                <iframe
                    src="https://www.youtube.com/embed/nV7X334cXkk?rel=0&modestbranding=1"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    style={{ position: "absolute", inset: 0, height: "100%", marginTop: 0 }}
                    title="a thousand numbers"
                />
            </div>
        </div>
    );
};

export default PhotoPage;
