import { Link } from "react-router-dom";

const Header = (props) => {
  return (
    <nav style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ display: "table" }}>
        <div style={{ marginBottom: "-10px" }}>
          <Link to="/" className="no-underline">
            <h1 className="mono" style={{ margin: "0" }}>
              <span className={props.primaryColor}>nathan</span>
              <span>.</span>
              <span className={props.secondaryColor}>baker</span>
              <span>() {"{"}</span>
            </h1>
          </Link>
        </div>
        <div className="subheaders" style={{ display: "flex", justifyContent: "space-between" }}>
          {props.subheaders.map((s) => (
            <Link
              key={`subheader-${s.name}`}
              className={"mono " + (s.selected ? "bold" : "gray")}
              to={s.href}
              target={s.newTab ? "_blank" : ""}
            >
              {s.name}
            </Link>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Header;
