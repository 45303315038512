import { useMediaQuery } from "react-responsive";
import { MdMail } from "react-icons/md";
import { RiLinkedinBoxFill, RiInstagramFill, RiYoutubeFill, RiSpotifyFill, RiGithubFill } from "react-icons/ri";

const Footer = (props) => {
    const sm = useMediaQuery({
        query: "(min-width: 600px)",
    });

    const md = useMediaQuery({
        query: "(min-width: 800px)",
    });

    const year = new Date().getFullYear();

    return (
        <footer style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <h1 className="mono closing-brace">{"}"}&ensp;</h1>
                {md ? (
                    <p className="footer-copyright gray mono weight-400">
                        <span className="gray">Copyright &copy; {year}</span>
                        <span className="gray">&nbsp;//&nbsp;</span>
                        <a href="/" className={props.secondaryColor}>
                            Nathan Baker
                        </a>
                    </p>
                ) : (
                    ""
                )}
            </div>
            <ul
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexGrow: "1",
                    flexShrink: "1",
                }}
                className="footer-list footer-links"
            >
                <li className="footer-link">
                    <a href="mailto:nathan.t.baker@okstate.edu" className="orange">
                        <MdMail size="2.5em" />
                    </a>
                </li>
                <li className="footer-link">
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://linkedin.com/in/nathan-baker-16173b1b8/"
                        className="blue"
                    >
                        <RiLinkedinBoxFill size="2.5em" />
                    </a>
                </li>
                <li className="footer-link">
                    <a target="_blank" rel="noreferrer" href="https://instagram.com/nate_bake_/" className="pink">
                        <RiInstagramFill size="2.5em" />
                    </a>
                </li>
                <li className="footer-link">
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://youtube.com/channel/UCDMhU58fPfb7kqvJJNVgU5w"
                        className="red"
                    >
                        <RiYoutubeFill size="2.5em" />
                    </a>
                </li>
                <li className="footer-link">
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://open.spotify.com/user/naynaybakebake"
                        className="green"
                    >
                        {/* href="https://open.spotify.com/playlist/4R078kIVCSGotunr22shUT?si=1fcad2dd6d1d49e3" */}
                        <RiSpotifyFill size="2.5em" />
                    </a>
                </li>
                <li className="footer-link">
                    <a target="_blank" rel="noreferrer" href="https://github.com/nate-bake" className="purple">
                        <RiGithubFill size="2.5em" />
                    </a>
                </li>
            </ul>
            {!md ? (
                <p className="footer-copyright gray mono weight-400" style={{ flexBasis: "100%", flexShrink: "0" }}>
                    <span className="gray">Copyright &copy; {year}</span>
                    {!sm ? <br /> : <span className="gray">&nbsp;//&nbsp;</span>}
                    <a href="/" className={props.secondaryColor}>
                        Nathan Baker
                    </a>
                </p>
            ) : (
                ""
            )}
        </footer>
    );
};

export default Footer;
