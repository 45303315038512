import { useEffect } from "react";
import { Link } from "react-router-dom";
import threads from "../../assets/seascape/threads.gif";
import students from "../../assets/seascape/mae_students_flight_test.jpg";
import FadeInImage from "../../components/FadeInImage";
import { useMediaQuery } from "react-responsive";

const SeascapePage = (props) => {
    useEffect(() => {
        props.setSubheaders([
            { name: "code:", href: "code", newTab: false, selected: false },
            { name: "half-flip", href: "code/half-flip", newTab: false, selected: false },
            { name: "seascape", href: "code/seascape", newTab: false, selected: true },
            { name: "rl-ai", href: "code/rl-ai", newTab: false, selected: false },
        ]);
        document.title = "nathan baker // seascape";
    }, []);

    const sm = useMediaQuery({
        query: "(min-width: 600px)",
    });

    const md = useMediaQuery({
        query: "(min-width: 900px)",
    });

    return (
        <>
            {md ? (
                <div style={{ display: "flex", width: "100%" }}>
                    <FadeInImage src={threads} style={{ width: "70%", paddingRight: "8px" }} />
                    <FadeInImage src={students} style={{ width: "30%", paddingLeft: "8px" }} />
                </div>
            ) : (
                <FadeInImage src={threads} style={{ width: "100%" }} />
            )}
            <p>
                During my undergraduate studies, I had the privilege of working as a research assistant for the OSU
                School of Mechanical and Aerospace Engineering. The primary project I contributed to as part of the{" "}
                <Link to="https://www.autophysics.net/" target="_blank" className="gray-hover">
                    <u>Autonomous Physics Group</u>
                </Link>{" "}
                was developing a Simple Educational Autopilot System with C++ Architecture and Python Exterior
                (SEASCAPE).
                <br />
                <br />
                SEASCAPE is a basic autopilot codebase that offers students a straightforward approach for implementing
                custom algorithms for aircraft estimation and control. Its modular design encourages experimental
                comparison between algorithms and also supports easy flight-mode switching to minimize risk during
                flight tests.
                <br />
                <br />
                The multithreaded system was built to support the Navio2 autopilot HAT for RaspberryPi. It utilizes C++
                for its core functions (polling sensor data, handling RC inputs, and adjusting servo motor PWM), and
                also provides two user-friendly Python threads for custom implementations. Thread frequencies and data
                paths are easily adjustable via JSON configuration file.
                <br />
                <br />
                SEASCAPE has been used by numerous aerospace engineering graduate students and supported other research
                efforts in autopilot design by unburdening users from having to worry about the pitfalls of concurrent
                programming in a real-time system.
                <br />
                <br />
                Find the paper here:&ensp;
                {!sm ? <br /> : ""}
                <Link to="https://doi.org/10.2514/6.2023-1019" target="_blank" className="gray-hover">
                    <u>https://doi.org/10.2514/6.2023-1019</u>
                </Link>
                <br />
                <br />
                {!md ? <FadeInImage src={students} style={{ width: "100%" }} /> : ""}
            </p>
        </>
    );
};

export default SeascapePage;
