import { useEffect } from "react";
import { Link } from "react-router-dom";
import rl from "../assets/tiles/rl.jpg";
import fifties from "../assets/tiles/fifties.png";
import navio from "../assets/tiles/navio.jpg";
import LinkCard from "../components/LinkCard";
import { useMediaQuery } from "react-responsive";

const CodePage = (props) => {
    useEffect(() => {
        props.setSubheaders([
            { name: "portfolio:", href: "portfolio", newTab: false, selected: false },
            { name: "code", href: "code", newTab: false, selected: true },
            { name: "photo", href: "photo", newTab: false, selected: false },
            { name: "video", href: "video", newTab: false, selected: false },
        ]);
        document.title = "nathan baker // code";
    }, []);

    const md = useMediaQuery({
        query: "(min-width: 900px)",
    });

    return (
        <div className="menu-container">
            <Link to="/code/half-flip" style={{ flexBasis: md ? "20%" : "100%" }}>
                <LinkCard
                    src={fifties}
                    className="code-card"
                    id="half-flip-card"
                    color="blue"
                    symbol="bracket"
                    display="half-flip"
                    style={{
                        ".card-title": {
                            h1: {
                                fontSize: md ? "max(min(3vw, 2.5rem), 1.5rem)" : "max(min(10vw, 4.5rem), 1.5rem)",
                            },
                        },
                    }}
                />
            </Link>
            <Link to="/code/seascape" style={{ flexBasis: md ? "20%" : "100%" }}>
                <LinkCard
                    src={navio}
                    className="code-card"
                    id="seascape-card"
                    color="orange"
                    symbol="bracket"
                    display="seascape"
                    style={{
                        ".card-title": {
                            h1: {
                                fontSize: md ? "max(min(3vw, 2.5rem), 1.5rem)" : "max(min(10vw, 4.5rem), 1.5rem)",
                            },
                        },
                    }}
                />
            </Link>
            <Link to="/code/rl-ai" style={{ flexBasis: md ? "20%" : "100%" }}>
                <LinkCard
                    src={rl}
                    className="code-card"
                    id="rl-ai-card"
                    color="green"
                    symbol="bracket"
                    display="rl-ai"
                    style={{
                        ".card-title": {
                            h1: {
                                fontSize: md ? "max(min(3vw, 2.5rem), 1.5rem)" : "max(min(10vw, 4.5rem), 1.5rem)",
                            },
                        },
                    }}
                />
            </Link>
        </div>
    );
};

export default CodePage;
