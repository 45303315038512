import { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import irish13 from "../../assets/irish/irish-13_2.jpg";
import irish2 from "../../assets/irish/irish-2_2.jpg";
import irish4 from "../../assets/irish/irish-4_2.jpg";
import irish5 from "../../assets/irish/irish-5_2.jpg";
import irish3 from "../../assets/irish/irish-3_2.jpg";
import irish6 from "../../assets/irish/irish-6_2.jpg";
import irish10 from "../../assets/irish/irish-10_2.jpg";
import irish11 from "../../assets/irish/irish-11_2.jpg";
import irish12 from "../../assets/irish/irish-12_2.jpg";
import irish15 from "../../assets/irish/irish-15.jpg";
import irish14 from "../../assets/irish/irish-14.jpg";
import "../../multi-photo-styles.css";
import avery from "../../assets/okstate/avery.jpg";
import lindy from "../../assets/okstate/lindy.jpg";
import griff from "../../assets/okstate/griff.jpg";
import dizzy from "../../assets/okstate/dizzy.jpg";
import remember from "../../assets/okstate/remember.jpg";

const HoopsPhotoPage = (props) => {
    useEffect(() => {
        props.setSubheaders([
            { name: "photo:", href: "photo", newTab: false, selected: false },
            { name: "bytes", href: "photo/bytes", newTab: false, selected: false },
            { name: "ai", href: "photo/detection", newTab: false, selected: false },
            { name: "screens", href: "photo/screens", newTab: false, selected: false },
            { name: "hoops", href: "photo/hoops", newTab: false, selected: true },
        ]);
        document.title = "nathan baker // basketball photography";
    }, []);

    return (
        <div>
            <div className="carousel-container">
                <div className="photo-set-container">
                    <Slider
                        {...{
                            speed: 750,
                            centerMode: true,
                            dots: true,
                            variableWidth: true,
                            adaptiveHeight: true,
                            infinite: true,
                            autoplay: true,
                            autoplaySpeed: 3000,
                            pauseOnFocus: false,
                            pauseOnHover: false,
                            focusOnSelect: false,
                            arrows: false,
                            onInit: async () => {
                                await new Promise((r) => setTimeout(r, 100));
                                const images = document.getElementsByClassName("photo");
                                for (let image of images) {
                                    image.style.opacity = 1;
                                }
                            },
                        }}
                    >
                        <div>
                            <img className="multi-photo photo" src={irish13} alt="" />
                            <div className="settings-table settings-1">
                                <p>ISO 2500</p>
                                <p>105 mm</p>
                                <p>f/4.0</p>
                                <p>1/320 sec</p>
                            </div>
                            <br />
                        </div>
                        <div>
                            <img className="multi-photo photo" src={irish2} alt="" />
                            <div className="settings-table settings-2">
                                <p>ISO 4000</p>
                                <p>105 mm</p>
                                <p>f/4.0</p>
                                <p>1/400 sec</p>
                            </div>
                            <br />
                        </div>
                        <div>
                            <img className="multi-photo photo" src={irish4} alt="" />
                            <div className="settings-table settings-3">
                                <p>ISO 800</p>
                                <p>50 mm</p>
                                <p>f/1.8</p>
                                <p>1/400 sec</p>
                            </div>
                            <br />
                        </div>
                        <div>
                            <img className="multi-photo photo" src={irish5} alt="" />
                            <div className="settings-table settings-5">
                                <p>ISO 500</p>
                                <p>50 mm</p>
                                <p>f/1.8</p>
                                <p>1/400 sec</p>
                            </div>
                            <br />
                        </div>
                        <div>
                            <img className="multi-photo photo" src={irish3} alt="" />
                            <div className="settings-table settings-4">
                                <p>ISO 6400</p>
                                <p>105 mm</p>
                                <p>f/4.0</p>
                                <p>1/400 sec</p>
                            </div>
                            <br />
                        </div>
                        <div>
                            <img className="multi-photo photo" src={irish6} alt="" />
                            <div className="settings-table settings-7">
                                <p>ISO 2500</p>
                                <p>42 mm</p>
                                <p>f/4.0</p>
                                <p>1/250 sec</p>
                            </div>
                            <br />
                        </div>
                        <div>
                            <img className="multi-photo photo" src={irish10} alt="" />
                            <div className="settings-table settings-11">
                                <p>ISO 4000</p>
                                <p>105 mm</p>
                                <p>f/4.0</p>
                                <p>1/400 sec</p>
                            </div>
                            <br />
                        </div>
                        <div>
                            <img className="multi-photo photo" src={irish11} alt="" />
                            <div className="settings-table settings-10">
                                <p>ISO 2500</p>
                                <p>28 mm</p>
                                <p>f/4.0</p>
                                <p>1/400 sec</p>
                            </div>
                            <br />
                        </div>
                        <div>
                            <img className="multi-photo photo" src={irish12} alt="" />
                            <div className="settings-table settings-9">
                                <p>ISO 2500</p>
                                <p>105 mm</p>
                                <p>f/4.0</p>
                                <p>1/320 sec</p>
                            </div>
                            <br />
                        </div>
                        <div>
                            <img className="multi-photo photo" src={irish15} alt="" />
                            <div className="settings-table settings-10">
                                <p>ISO 4000</p>
                                <p>80 mm</p>
                                <p>f/4.0</p>
                                <p>1/400 sec</p>
                            </div>
                            <br />
                        </div>
                        <div>
                            <img className="multi-photo photo" src={avery} alt="" />
                            <div className="settings-table">
                                <p>ISO 1600</p>
                                <p>105 mm</p>
                                <p>f/4.0</p>
                                <p>1/400 sec</p>
                            </div>
                            <br />
                        </div>
                        <div>
                            <img className="multi-photo photo" src={dizzy} alt="" />
                            <div className="settings-table">
                                <p>ISO 5000</p>
                                <p>28 mm</p>
                                <p>f/5.6</p>
                                <p>1/400 sec</p>
                            </div>
                            <br />
                        </div>
                        <div>
                            <img className="multi-photo photo" src={lindy} alt="" />
                            <div className="settings-table">
                                <p>ISO 2000</p>
                                <p>105 mm</p>
                                <p>f/5.6</p>
                                <p>1/400 sec</p>
                            </div>
                            <br />
                        </div>
                        <div>
                            <img className="multi-photo photo" src={griff} alt="" />
                            <div className="settings-table">
                                <p>ISO 3200</p>
                                <p>105 mm</p>
                                <p>f/4.0</p>
                                <p>1/400 sec</p>
                            </div>
                            <br />
                        </div>
                        <div>
                            <img className="multi-photo photo" src={remember} alt="" />
                            <div className="settings-table">
                                <p>ISO 1000</p>
                                <p>105 mm</p>
                                <p>f/4.0</p>
                                <p>1/250 sec</p>
                            </div>
                            <br />
                        </div>
                        <div>
                            <img className="multi-photo photo" src={irish14} alt="" />
                            <div className="settings-table settings-8">
                                <p>ISO 2500</p>
                                <p>57 mm</p>
                                <p>f/4.0</p>
                                <p>1/250 sec</p>
                            </div>
                            <br />
                        </div>
                    </Slider>
                </div>
            </div>
            <br />
            <p className="description gray center">18 January 2020&emsp;||&emsp;27 January 2020 </p>
        </div>
    );
};

export default HoopsPhotoPage;
