import { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sensor from "../../assets/bytes/sensor.jpg";
import photoshop from "../../assets/bytes/photoshop_final_cropped.jpg";
import purple from "../../assets/bytes/purple3.jpg";
import piano from "../../assets/bytes/piano_moonlight.jpg";
import emissions from "../../assets/bytes/emissions.jpg";
import jumper from "../../assets/bytes/jumper3.jpg";
import sinowal from "../../assets/bytes/sinowal3.jpg";
import sunburst from "../../assets/bytes/sunburst3.jpg";
import mamba from "../../assets/bytes/mamba3.jpg";
import shamoon from "../../assets/bytes/shamoon3.jpg";
import { useMediaQuery } from "react-responsive";

const BytesPhotoPage = (props) => {
    useEffect(() => {
        props.setSubheaders([
            { name: "photo:", href: "photo", newTab: false, selected: false },
            { name: "bytes", href: "photo/bytes", newTab: false, selected: true },
            { name: "ai", href: "photo/detection", newTab: false, selected: false },
            { name: "screens", href: "photo/screens", newTab: false, selected: false },
            { name: "hoops", href: "photo/hoops", newTab: false, selected: false },
        ]);
        document.title = "nathan baker // bytes as images";
    }, []);

    const isTouchscreen = !useMediaQuery({
        query: "(hover: hover) and (pointer: fine)",
    });

    return (
        <div>
            <div className="carousel-container">
                <div className="photo-set-container">
                    <Slider
                        {...{
                            speed: isTouchscreen ? 750 : 1500,
                            centerMode: false,
                            dots: true,
                            variableWidth: true,
                            adaptiveHeight: true,
                            infinite: true,
                            autoplay: true,
                            autoplaySpeed: 3000,
                            pauseOnFocus: false,
                            pauseOnHover: false,
                            focusOnSelect: false,
                            arrows: false,
                            fade: isTouchscreen ? false : true,
                            onInit: async () => {
                                await new Promise((r) => setTimeout(r, 100));
                                const images = document.getElementsByClassName("photo");
                                for (let image of images) {
                                    image.style.opacity = 1;
                                }
                            },
                        }}
                    >
                        <div>
                            <img className="photo" src={sensor} alt="" />
                            <div className="settings-table settings-table-titled">
                                <p>sensor.ARW</p>
                                <p>ISO 100</p>
                                <p>16 mm</p>
                                <p>f/16</p>
                                <p>4.0 sec</p>
                            </div>
                        </div>
                        <div>
                            <img className="photo" src={photoshop} alt="" />
                            <div className="settings-table settings-table-titled">
                                <p>photoshop.exe</p>
                                <p>ISO 1250</p>
                                <p>50 mm</p>
                                <p>f/2.8</p>
                                <p>1/125 sec</p>
                            </div>
                        </div>
                        <div>
                            <img className="photo" src={purple} alt="" />
                            <div className="settings-table settings-table-titled">
                                <p>the_holy_bible.txt</p>
                                <p>ISO 125</p>
                                <p>50 mm</p>
                                <p>f/5.6</p>
                                <p>1/125 sec</p>
                            </div>
                        </div>
                        <div>
                            <img className="photo" src={piano} alt="" />
                            <div className="settings-table settings-table-titled">
                                <p>moonlight_sonata.mp3</p>
                                <p>ISO 500</p>
                                <p>50 mm</p>
                                <p>f/4.0</p>
                                <p>1/125 sec</p>
                            </div>
                        </div>
                        <div>
                            <img className="photo" src={emissions} alt="" />
                            <div className="settings-table settings-table-titled">
                                <p>emissions_report_2021.pdf</p>
                                <p>ISO 500</p>
                                <p>50 mm</p>
                                <p>f/4.0</p>
                                <p>1/125 sec</p>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
            <br />
            <p className="description">
                A meditation on the idea that every digital photograph is a translation from the physical to the
                numerical, this series considers the inverse of this process by persuading a computer to interpret other
                data files as though they were compiled in an image format. Files including the full text of The Bible
                and a recording of Beethoven's
                <em>Moonlight Sonata</em> were converted to grayscale images and systematically embedded into the RGB
                channels of other digital photographs. The results were saved in a lossless format, making it possible
                to&nbsp;
                <a href={`${process.env.PUBLIC_URL}/files/bytes.zip`} className="gray-hover">
                    <u>download the pictures and recover the documents in their original states.</u>
                </a>
                <br />
                {/* <br />A more playful demonstration is also included in this set, where the Photoshop executable file was
                converted to a TIFF file and then altered using the very application that it was designed to initiate. */}
            </p>
            <br />
            <div style={{ borderTop: "2px solid #d5d5d5", width: "100%" }} />
            <br />
            <br />
            <div className="carousel-container">
                <div className="photo-set-container">
                    <Slider
                        {...{
                            speed: isTouchscreen ? 750 : 1500,
                            centerMode: false,
                            dots: true,
                            variableWidth: true,
                            adaptiveHeight: true,
                            infinite: true,
                            autoplay: true,
                            autoplaySpeed: 3000,
                            pauseOnFocus: false,
                            pauseOnHover: false,
                            focusOnSelect: false,
                            fade: isTouchscreen ? false : true,
                            arrows: false,
                            onInit: async () => {
                                await new Promise((r) => setTimeout(r, 100));
                                const images = document.getElementsByClassName("photo");
                                for (let image of images) {
                                    image.style.opacity = 1;
                                }
                            },
                        }}
                    >
                        <div>
                            <img className="photo" src={jumper} alt="" />
                            <div className="settings-table settings-table-titled">
                                <p>Jumper.B</p>
                                <p>ISO 100</p>
                                <p>16 mm</p>
                                <p>f/16</p>
                                <p>4.0 sec</p>
                            </div>
                        </div>
                        <div>
                            <img className="photo" src={sinowal} alt="" />
                            <div className="settings-table settings-table-titled">
                                <p>Trojan.Sinowal</p>
                                <p>ISO 1250</p>
                                <p>50 mm</p>
                                <p>f/2.8</p>
                                <p>1/125 sec</p>
                            </div>
                        </div>
                        <div>
                            <img className="photo" src={sunburst} alt="" />
                            <div className="settings-table settings-table-titled">
                                <p>WinX.SUNBURST</p>
                                <p>ISO 125</p>
                                <p>50 mm</p>
                                <p>f/5.6</p>
                                <p>1/125 sec</p>
                            </div>
                        </div>
                        <div>
                            <img className="photo" src={mamba} alt="" />
                            <div className="settings-table settings-table-titled">
                                <p>Ransomware.Mamba</p>
                                <p>ISO 500</p>
                                <p>50 mm</p>
                                <p>f/4.0</p>
                                <p>1/125 sec</p>
                            </div>
                        </div>
                        <div>
                            <img className="photo" src={shamoon} alt="" />
                            <div className="settings-table settings-table-titled">
                                <p>Shamoon</p>
                                <p>ISO 500</p>
                                <p>50 mm</p>
                                <p>f/4.0</p>
                                <p>1/125 sec</p>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
            <br />
            <p className="description">
                Having proved a way to hide information within photographs, this set expands on this idea further by
                "infecting" images with destructive computer viruses. A set of default Windows desktop backgrounds was
                used as hosts since many of the viruses target Windows systems. In fact, while producing these images,
                my computer immediately recognized them as threats and frustratingly deleted them.
            </p>
            <br />
            <p className="gray center">
                Completed as part of Photography Studio
                <br />2 December 2021
            </p>
        </div>
    );
};

export default BytesPhotoPage;
