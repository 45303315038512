import { useEffect } from "react";
import { Link } from "react-router-dom";
import jungle from "../assets/tiles/jungle.jpg";
import vlog2 from "../assets/tiles/vlog2.jpg";
import LinkCard from "../components/LinkCard";
import { useMediaQuery } from "react-responsive";

const VideoPage = (props) => {
    useEffect(() => {
        props.setSubheaders([
            { name: "portfolio:", href: "portfolio", newTab: false, selected: false },
            { name: "code", href: "code", newTab: false, selected: false },
            { name: "photo", href: "photo", newTab: false, selected: false },
            { name: "video", href: "video", newTab: false, selected: true },
        ]);
        document.title = "nathan baker // video";
    }, []);

    const md = useMediaQuery({
        query: "(min-width: 900px)",
    });

    return (
        <div className="menu-container">
            <Link to="/video/irish" style={{ flexBasis: md ? "20%" : "100%" }}>
                <LinkCard
                    src={jungle}
                    className="video-card"
                    id="irish-card"
                    color="green"
                    symbol="bracket"
                    display="irish"
                    style={{
                        ".card-title": {
                            h1: {
                                fontSize: md ? "max(min(5vw, 4rem), 1.5rem)" : "max(min(10vw, 4.5rem), 1.5rem)",
                            },
                        },
                    }}
                />
            </Link>
            <Link to="/video/vlog" style={{ flexBasis: md ? "20%" : "100%" }}>
                <LinkCard
                    src={vlog2}
                    className="video-card"
                    id="vlog-card"
                    color="red"
                    symbol="bracket"
                    display="vlog"
                    style={{
                        ".card-title": {
                            h1: {
                                fontSize: md ? "max(min(5vw, 4rem), 1.5rem)" : "max(min(10vw, 4.5rem), 1.5rem)",
                            },
                        },
                    }}
                />
            </Link>
        </div>
    );
};

export default VideoPage;
